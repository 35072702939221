import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  computed,
  viewChild,
  inject,
  signal,
  effect,
  untracked,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Maybe,
  Playlist,
  Channel,
  Asset,
  PlaylistStatus,
  Location,
  ChannelGroup,
  GetPlaylistDetailedGQL,
  DuplicatePlaylistsGQL,
  ChannelPlaylist,
  ChannelsForChannelListFragment,
  PlaylistType,
} from '@designage/gql';
import {
  EncryptionService,
  PlaylistAsset,
  PlaylistAssetContent,
  PlaylistViewService,
  PlaylistViewSetting,
  PlaylistEditorService,
  ToasterService,
  ChannelService,
  UiMode,
  CurrentUserService,
  ResponsiveUiService,
  PlaylistTabs,
  SessionService,
} from '@desquare/services';
import { SubSink } from 'subsink';
import { IPlaylistForm } from '@desquare/interfaces';
import { getPlaylistStatus } from '@desquare/utils';
import {
  SplitComponent,
  SplitAreaDirective,
  AngularSplitModule,
} from 'angular-split';
import { cloneDeep } from 'lodash';
import { DateValidator } from '@desquare/validators';
import moment from 'moment';
import {
  AsyncPipe,
  CommonModule,
  DatePipe,
  NgTemplateOutlet,
} from '@angular/common';
import { ChannelSelectionMode } from '@desquare/enums';
import { Router } from '@angular/router';
import {
  NgbModal,
  NgbNavChangeEvent,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PlaylistPublishingDialogComponent } from '../playlist-publishing-dialog/playlist-publishing-dialog.component';
import { PlaylistRevertVersionDialogComponent } from '../playlist-revert-version-dialog/playlist-revert-version-dialog.component';
import { PlaylistCreateCopyDialogComponent } from '../playlist-create-copy-dialog/playlist-create-copy-dialog.component';
import { ContentPreviewComponent } from '@designage/app/shared/content-preview/content-preview.component';
import { Observable, lastValueFrom } from 'rxjs';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { PlaylistSequenceManagerComponent } from '@designage/app/playlist-sequence/playlist-sequence-manager/playlist-sequence-manager.component';
import { ChannelRegionSelectionComponent } from '@designage/app/shared/channel-region-selection/channel-region-selection.component';
import { PlaylistVersionComponent } from '../playlist-version/playlist-version.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { MediaListComponent } from '@designage/app/media/media-list/media-list.component';
import { PlaylistPreviewStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
    NgbNavModule,
    AngularSplitModule,
    PlaylistSequenceManagerComponent,
    ContentPreviewComponent,
    ChannelRegionSelectionComponent,
    PlaylistVersionComponent,
    DatepickerComponent,
    DateProxyPipe,
    MediaListComponent,
  ],
  selector: 'app-playlist-form',
  templateUrl: './playlist-form.component.html',
  styleUrls: ['./playlist-form.component.scss'],
  providers: [DatePipe],
})
export class PlaylistFormComponent implements OnInit, OnDestroy, OnChanges {
  private subs = new SubSink();
  playlistPreviewStore = inject(PlaylistPreviewStore);

  @ViewChild('playlistNameInput') playlistNameInput!: ElementRef;
  @ViewChild('split') split!: SplitComponent;
  @ViewChild('mainPanel') mainPanel!: SplitAreaDirective;
  @ViewChild('secondaryPanel') secondaryPanel!: SplitAreaDirective;
  preview = viewChild<ContentPreviewComponent>('preview');

  @Output() submitted = new EventEmitter<IPlaylistForm>();
  @Output() values = new EventEmitter<IPlaylistForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();
  @Output() formInitialized = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() loadingMessage = new EventEmitter<string>();
  @Output() userAction = new EventEmitter<string>();
  @Output() hasUnsavedPlaylistChanges = new EventEmitter<boolean>();
  @Output() playlistPublished = new EventEmitter<void>();

  @Input() playlist?: Maybe<Playlist>;
  @Input() formId = 'playlistForm';
  @Input() isCreateNewAsset = false;
  @Input() nameRequired = false;
  @Input() publishDestinationRequired = false;
  @Input() invokedFrom?: string;

  simulateDateTime = computed(() => {
    return this.preview()?.simulateDateTime() || null;
  });

  get isInteractive() {
    return this.playlistType === PlaylistType.Interactive;
  }
  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  playlistForm!: FormGroup;
  loadingDevices = false;
  loadingProfiles = false;
  loadingChannels = false;
  publishedAssets: Asset[] = [];
  addedAssetIds: string[] = [];
  isEditingName = false;
  updatedAt!: Maybe<string>;
  resourceCount!: number;
  playlistStatus = PlaylistStatus;
  isStartDateChecked = false;
  isEndDateChecked = false;

  initialized = false;
  profileId!: Maybe<string>;
  comment!: Maybe<string>;
  validSequenceContentDuration = true;
  pendingPlaylist!: Maybe<Playlist>;
  isLoadPendingChange = true;
  isLoadingPlaylist = false;
  isValueSet = false;
  currentTab!: PlaylistTabs;
  selectedChannels: ChannelPlaylist[] = [];
  selectedLocations: Location[] = [];
  selectedChannelGroups: ChannelGroup[] = [];
  channelSelectionMode = ChannelSelectionMode;
  channelFilter!: string;
  showAlert!: boolean;
  invalidDateRange!: boolean;
  viewSettings!: PlaylistViewSetting;
  activeChannels: string[] = [];
  simpleUiActive = false;
  uiMode$!: Observable<UiMode>;
  playlistStartDate!: string;
  playlistEndDate!: string;
  activeIdSettingsPanel = signal<number>(1);

  channels: Maybe<ChannelsForChannelListFragment>[] = [];

  _changeDetected!: boolean;
  get changeDetected() {
    return this._changeDetected;
  }
  set changeDetected(value: boolean) {
    this._changeDetected = value;
    this.playlistViewService.hasUnsavedPlaylistChanges = value;
  }

  // TODO: enable this with a custom feature connected to profile
  advancedScheduleEnabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private playlistEditorService: PlaylistEditorService,
    private datePipe: DatePipe,
    private session: SessionService,
    private encryptionService: EncryptionService,
    private modalService: NgbModal,
    private getPlaylistGQL: GetPlaylistDetailedGQL,
    private duplicatePlaylistsGQL: DuplicatePlaylistsGQL,
    private router: Router,
    private toasterService: ToasterService,
    private playlistViewService: PlaylistViewService,
    private channelService: ChannelService,
    public currentUserService: CurrentUserService,
    public responsiveUiService: ResponsiveUiService,
  ) {
    effect(() => {
      const activeIdSettingsPanel = this.activeIdSettingsPanel();
      untracked(() => {
        if (activeIdSettingsPanel !== 2) {
          this.playlistPreviewStore.setContentStatus(false);
        } else {
          this.playlistPreviewStore.setContentStatus(true);
        }
        console.log('activeIdSettingsPanel', this.activeIdSettingsPanel());
      });
    });
  }

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.initSubscriptions();
    this.setFormState();
    if (this.isCreateNewAsset) {
      this.editPlaylistNameInput();
    }
    this.responsiveUiService.isMobileDevice()
      ? this.activeIdSettingsPanel.set(1)
      : this.activeIdSettingsPanel.set(2);
    this.isInteractive
      ? this.activeIdSettingsPanel.set(3)
      : this.activeIdSettingsPanel.set(2);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  // promptUnsavedChanges() {
  //   // TODO: Work around only. Make the modal confirmation dialog work for this.
  //   if (
  //     this.changeDetected &&
  //     !this.isCreateNewAsset &&
  //     confirm('There are unsaved changes. Do you want to save it?')
  //   ) {
  //     this.addPendingPlaylist();
  //     this.save();
  //   } else {
  //     this.removePendingPlaylist();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.playlistForm) {
      this.initForm();
    }

    if (changes.playlist && changes.playlist.currentValue && this.playlist) {
      this.pendingPlaylist =
        this.playlistEditorService.getCurrentPendingPlaylist({
          playlist: this.playlist,
        });

      this.loadPendingPlaylist(false);
      this.initViewSettings();

      let defaultTab = PlaylistTabs.PREVIEW;
      if (this.invokedFrom === 'channelPage' || this.isInteractive) {
        defaultTab = PlaylistTabs.CONTENT_GALLERY;
      }
      this.currentTab =
        this.invokedFrom != 'channelPage'
          ? this.viewSettings.currentTab || defaultTab
          : defaultTab;
    }

    this.updatedAt = this.transformDate(this.playlist?.updatedAt);
    if (this.playlist?.assets) {
      this.resourceCount = 0;

      for (const asset of this.playlist.assets) {
        if (asset.content) {
          this.resourceCount += asset.content.length;
        }
      }
    }

    this.currentTab = this.publishDestinationRequired
      ? PlaylistTabs.PUBLISH_DESTINATION
      : this.currentTab;
    if (this.isCreateNewAsset) {
      this.editPlaylistNameInput();
    }
  }

  initVariables() {
    this.profileId = this.session.profileId() || '';

    this.playlistEditorService.editingSequences = [];
    this.currentTab = PlaylistTabs.PREVIEW;
    this.showAlert = true;
    this.nameRequired = false;
    this.invalidDateRange = false;
    this.publishDestinationRequired = false;
    this.changeDetected = false;
  }

  initViewSettings() {
    if (this.playlist) {
      this.selectedChannels = this.playlist.channelRegions || [];

      const setting = this.playlistViewService.getViewSettings(
        this.playlist.id,
      );
      if (setting) {
        this.viewSettings = setting;
      } else {
        // TODO: this should be moved inside PlaylistViewService
        const playlistAssets: PlaylistAsset[] = [];
        for (const asset of this.playlist.assets) {
          const playlistAssetContents: PlaylistAssetContent[] = [];
          for (const content of asset.content) {
            if (content.id) {
              playlistAssetContents.push({
                contentId: content.id,
                isOpen: false,
                isPinned: false,
              });
            }
          }
          // Default first content to be selected
          playlistAssetContents[0].isOpen = true;
          playlistAssets.push({
            assetId: asset.id,
            isCollapsed: false,
            contents: playlistAssetContents,
          });
        }
        this.viewSettings = {
          playlistId: this.playlist.id,
          currentTab: this.currentTab,
          assets: playlistAssets,
        };
        this.playlistViewService.saveViewSettings(this.viewSettings);
      }
    }
  }

  initForm() {
    this.playlistForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        profileId: [null, Validators.required],
        startDate: [{ value: null, disabled: true }],
        endDate: [{ value: null, disabled: true }],
        channelIds: [null],
        channelGroupIds: [null],
        locationIds: [null],
        assets: [],
        comment: [null],
      },
      {
        validators: [DateValidator.dateRange('startDate', 'endDate')],
      },
    );
  }

  initSubscriptions() {
    this.uiMode$ = this.currentUserService.getUiMode();

    this.playlistEditorService.editingSequencesChange.subscribe((assets) => {
      if (this.playlist) {
        // this.playlist.assets = assets;
        this.playlist = {
          ...this.playlist,
          assets,
        };
      }
    });
    if (this.profileId && !this.initialized) {
      this.playlistForm.patchValue({ profileId: this.profileId });
      this.initialized = true;
      if (this.isCreateNewAsset) {
        /* this.playlist = this.playlistEditorService.getCurrentPendingPlaylist({
          profileId: this.profileId,
        });*/
        this.playlistEditorService.createAsset(this.profileId);
        setTimeout(
          () => this.playlistEditorService.emitEditingSequencesChange(),
          1000,
        );
      }
    }

    this.values.emit(this.playlistForm.value);

    this.initChannels();
  }

  async initChannels() {
    this.channels = [];
    this.channels = await this.channelService.getCurrentProfileChannels();
  }

  setFormState() {
    this.subs.sink = this.playlistForm.valueChanges.subscribe(
      (values: IPlaylistForm) => {
        this.setPlaylistStatus();
        if (this.validSequenceContentDuration) {
          this.valid.emit(this.playlistForm.valid);
          this.pristine.emit(this.playlistForm.pristine);
          this.values.emit(values);
        }
        this.setPendingPlaylist();
      },
    );

    this.subs.sink = this.playlistEditorService.sequenceTouch.subscribe(
      (isTouched: boolean) => {
        if (isTouched) {
          this.playlistForm.controls.assets.markAsDirty();
          this.setPendingPlaylist();
        }
      },
    );

    this.subs.sink =
      this.playlistEditorService.sequenceValidityChanges.subscribe(
        (isValid: boolean) => {
          this.validSequenceContentDuration = isValid;
          if (this.playlistForm.valid) {
            this.valid.emit(isValid);
          }
        },
      );

    this.subs.sink =
      this.playlistEditorService.editingSequencesChange.subscribe(() => {
        this.playlistForm.markAsDirty();
        this.updatePlaylistForm();
        this.setPendingPlaylist();
      });
  }

  /**
   * db to form
   * @param currentPlaylist
   */
  setControlValues(currentPlaylist: Playlist) {
    this.isValueSet = false;
    this.setPlaylistPublishLocations();
    this.selectedChannels = currentPlaylist.channelRegions || [];

    this.playlistForm.patchValue({
      name: currentPlaylist.name,
      startDate: currentPlaylist.startDate
        ? moment(currentPlaylist.startDate).startOf('day')
        : null,
      endDate: currentPlaylist.endDate
        ? moment(currentPlaylist.endDate).endOf('day')
        : null,
      profileId: this.profileId,
      channelIds: this.selectedChannels
        ? this.selectedChannels.map((x) => {
            return { id: x.channelId, region: x.region };
          })
        : null,
      channelGroupIds: this.selectedChannelGroups
        ? this.selectedChannelGroups.map((x) => x.id)
        : null,
      locationIds: this.selectedLocations
        ? this.selectedLocations.map((x) => x.id)
        : null,
    });

    if (currentPlaylist.startDate) {
      this.isStartDateChecked = true;
      this.playlistStartDate =
        this.playlistForm.controls.startDate.value.format('YYYY-MM-DD');
      this.playlistForm.controls.startDate.enable();
    }

    if (currentPlaylist.endDate) {
      this.isEndDateChecked = true;
      this.playlistEndDate =
        this.playlistForm.controls.endDate.value.format('YYYY-MM-DD');
      this.playlistForm.controls.endDate.enable();
    }

    this.playlistForm.controls.startDate.markAsPristine();
    this.playlistForm.controls.endDate.markAsPristine();

    this.setPlaylistAssetsAndChannel(currentPlaylist);
    this.setPlaylistStatus();

    this.playlistEditorService.emitEditingSequencesChange();

    this.playlistForm.markAsPristine();
    this.isValueSet = true;
  }

  setPlaylistPublishLocations() {
    /*
    if (this.playlist?.channels) {
      // this.selectedChannels = this.playlist.channelRegions;
      const locationsWithUnSelectedChannels = this.playlist?.locations.filter(
        (location) =>
          location.channels?.some((channel) => {
            return (
              this.playlist?.channels.findIndex((x) => x.id === channel.id) ===
              -1
            );
          })
      );
      this.selectedLocations = this.playlist?.locations.filter((location) => {
        return locationsWithUnSelectedChannels?.findIndex(
          (x) => x.id === location.id
        );
      });

      const channelGroupsWithUnSelectedChannels =
        this.playlist.channelGroups.filter((channelGroup) =>
          channelGroup.channels.some((channel) => {
            return (
              this.playlist?.channels.findIndex((x) => x.id === channel.id) ===
              -1
            );
          })
        );

      this.selectedChannelGroups = this.playlist?.channelGroups.filter(
        (channelGroup) => {
          return channelGroupsWithUnSelectedChannels.findIndex(
            (x) => x.id === channelGroup.id
          );
        }
      );
    }*/
  }

  setSelectedChannels(channels: ChannelPlaylist[]) {
    this.selectedChannels = channels;
    const p = this.preview();
    if (p) {
      p.selectedChannelRegions = channels;
    }
    this.publishDestinationRequired = this.selectedChannels.length
      ? false
      : true;
    const channelRegionList = channels.map((x) => {
      return { id: x.channelId, region: x.region };
    });

    this.playlistForm.patchValue({
      channelIds: channelRegionList,
    });
    this.changeDetected = true;
  }

  setPlaylistAssetsAndChannel(currentPlaylist: Playlist) {
    // if (this.playlist && this.playlist.profile && this.profileId && this.profileId === this.playlist.profile.id) {
    if (currentPlaylist) {
      this.playlistEditorService.playlistType = currentPlaylist.type;
      this.playlist?.assets.forEach((a) =>
        this.playlistEditorService.fixAssetItemsMetadata(a),
      );
      this.playlistEditorService.editingSequences = [...currentPlaylist.assets];
      // this.playlistEditorService.editingSequences = currentPlaylist.assets.sort(
      //   (a, b) => (a.sequence && b.sequence && a.sequence > b.sequence ? 1 : -1)
      // );

      if (currentPlaylist.publishedPlaylist) {
        this.publishedAssets = cloneDeep(
          currentPlaylist.publishedPlaylist.assets,
        );
      }

      if (currentPlaylist.status === PlaylistStatus.Published) {
        this.publishedAssets = cloneDeep(currentPlaylist.assets);
      }
    } else {
      this.playlistEditorService.editingSequences = [];
    }
    this.playlistEditorService.updateAssetsEndTime();
    this.setEmptyAssetsContent();
    this.updatePlaylistForm();
  }

  setEmptyAssetsContent() {
    this.playlistEditorService.editingSequences.forEach((asset) => {
      if (!asset.content) {
        asset.content = [];
      }
    });
  }

  emitLoading() {
    this.loading.emit(
      this.loadingDevices || this.loadingProfiles || this.loadingChannels,
    );
  }

  // event is from form submission
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPlaylistForm(event: any, values: IPlaylistForm) {
    // invariant error fix
    this.submitted.emit(
      // this.playlistEditorService.transformPlaylistFormValues(values, this.profileId)
      values,
    );
  }

  onChannelSelectChange(channels: Channel[]) {
    if (this.playlistForm.controls.channelIds) {
      this.playlistForm.controls.channelIds.markAsDirty();
    }

    this.playlistForm.patchValue({
      channelIds: channels.map((x) => {
        return { id: x.id, region: x.region };
      }),
    });
  }

  updatePlaylistForm() {
    this.playlistForm.patchValue({
      assets: this.playlistEditorService.editingSequences,
    });
    this.setStartTimeStatus();
    this.pristine.emit(this.playlistForm.pristine);
    if (this.validSequenceContentDuration) {
      this.valid.emit(this.playlistForm.valid);
    }
  }

  setStartTimeStatus() {
    const duplicateStartTime = this.playlistEditorService.getDuplicateAssets();
    const incorrectStartTime = this.playlistEditorService.getInvalidStartTime();

    if (duplicateStartTime || incorrectStartTime) {
      this.playlistForm.controls.assets.setErrors({
        duplicateStartTime,
        incorrectStartTime,
      });
    } else {
      this.playlistForm.controls.assets.setErrors(null);
    }
  }

  setPlaylistStatus() {
    this.playlistEditorService.currentPlaylistStatus.set(
      getPlaylistStatus(
        this.playlistForm.controls.startDate.value,
        this.playlistForm.controls.endDate.value,
        this.simulateDateTime(),
      ),
    );

    if (this.playlist?.publishedPlaylist) {
      this.playlistEditorService.publishedPlaylistStatus.set(
        getPlaylistStatus(
          this.playlist.publishedPlaylist.startDate,
          this.playlist.publishedPlaylist.endDate,
          this.simulateDateTime(),
        ),
      );
    }

    if (this.playlist?.status === PlaylistStatus.Published) {
      this.playlistEditorService.publishedPlaylistStatus.set(
        getPlaylistStatus(
          this.playlist.startDate,
          this.playlist.endDate,
          this.simulateDateTime(),
        ),
      );
    }
  }
  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheckBoxStartDate(event: any) {
    this.changeDetected = true;
    if (event.target.checked === true) {
      this.isStartDateChecked = true;
      this.playlistForm.controls.startDate.enable();
      this.playlistForm.patchValue({
        startDate: this.playlist?.startDate
          ? moment(this.playlist?.startDate).startOf('day')
          : moment(new Date()).startOf('day'),
      });
      this.playlistStartDate =
        this.playlistForm.controls.startDate.value.format('YYYY-MM-DD');
    } else {
      this.isStartDateChecked = false;
      this.playlistStartDate = '';
      this.playlistForm.controls.startDate.disable();
      this.playlistForm.controls.startDate.patchValue(null);
    }
  }

  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCheckBoxEndDate(event: any) {
    this.changeDetected = true;
    if (event.target.checked === true) {
      this.isEndDateChecked = true;
      this.playlistForm.controls.endDate.enable();
      this.playlistForm.patchValue({
        endDate: this.playlist?.endDate
          ? moment(this.playlist?.endDate).startOf('day')
          : this.playlist?.startDate
            ? moment(this.playlist.startDate).startOf('day').add(1, 'd')
            : moment(new Date()).startOf('day').add(1, 'd'),
      });
      this.playlistEndDate =
        this.playlistForm.controls.endDate.value.format('YYYY-MM-DD');
    } else {
      this.isEndDateChecked = false;
      this.playlistEndDate = '';
      this.playlistForm.controls.endDate.disable();
      this.playlistForm.controls.endDate.patchValue(null);
    }
  }

  playlistScheduleChange() {
    this.changeDetected = true;
    if (this.playlist) {
      this.playlist.startDate = this.playlistStartDate
        ? new Date(this.playlistStartDate)
        : null;
      this.playlistForm.controls.startDate.patchValue(this.playlist.startDate);
      this.playlist.endDate = this.playlistEndDate
        ? new Date(this.playlistEndDate)
        : null;
      this.playlistForm.controls.endDate.patchValue(this.playlist.endDate);
      this.playlistForm.markAsDirty();
    }
    this.playlistEditorService.playlistScheduleChanges.emit(true);
  }

  updatePlaylistName() {
    this.isEditingName = false;
    this.nameRequired = false;
  }

  transformDate(dateString: string) {
    return this.datePipe.transform(dateString, 'short');
  }

  loadPendingPlaylist(value: boolean) {
    this.loading.emit(true);
    this.isLoadingPlaylist = true;
    this.isLoadPendingChange = value;
    if (this.isLoadPendingChange && this.pendingPlaylist) {
      this.setControlValues(this.pendingPlaylist);
    } else if (!this.isLoadPendingChange && this.playlist) {
      this.setControlValues(this.playlist);
    }
    this.loading.emit(false);
    this.isLoadingPlaylist = false;
    this.showAlert = !value;
  }

  setPendingPlaylist() {
    if (
      this.profileId &&
      !this.playlistForm.pristine &&
      !this.isLoadingPlaylist
    ) {
      const { ...profile } = this.session.profile();
      const playlistValue = {
        ...cloneDeep(this.playlistForm.value),
        id: this.playlist?.id,
        profile,
        status: this.playlist?.status,
        startDate: this.playlistForm.value.startDate ?? null,
        endDate: this.playlistForm.value.endDate ?? null,
        publishedPlaylist: this.playlist?.publishedPlaylist,
        assets: this.playlistEditorService.editingSequences,
        channels: this.playlist?.channels,
        updatedAt: new Date().toISOString(),
      };
      this.playlistEditorService.addToPendingPlaylists(playlistValue);
      this.changeDetected = true;
    }
  }

  async publish() {
    let validForm = true;
    // if (!this.selectedChannels.length) {
    //   this.publishDestinationRequired = true;
    //   this.currentTab = PlaylistTabs.PUBLISH_DESTINATION;
    //   validForm = false;
    // }
    if (!this.playlistForm.controls.name.value) {
      this.nameRequired = true;
      validForm = false;
    }
    if (this.playlistForm.hasError('dateRange')) {
      this.invalidDateRange = true;
      validForm = false;
    }

    if (!validForm) {
      return;
    }

    const channels = await this.channelService.getCurrentProfileChannels();
    const selectedChannelIds = this.selectedChannels.map((x) => x.channelId);
    const modal = this.modalService.open(PlaylistPublishingDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.playlistName =
      this.playlistForm.controls.name.value;
    modal.componentInstance.channels = channels
      .filter((x) => !!x && selectedChannelIds?.includes(x.id))
      .map((x) => x?.name)
      .filter((x) => !!x);
    modal.componentInstance.activeChannels = this.activeChannels;
    modal.result
      .then((value) => {
        if (value && 'comment' in value) {
          this.playlistForm.patchValue({ comment: value.comment });
          this.values.emit(this.playlistForm.value);
        }
        this.userAction.emit('PUBLISH');
        this.changeDetected = false;
        this.playlistPublished.emit();
      })
      .catch(() => {});
  }

  save() {
    let validForm = true;
    if (!this.playlistForm.controls.name.value) {
      this.nameRequired = true;
      validForm = false;
    }

    if (this.playlistForm.hasError('dateRange')) {
      this.invalidDateRange = true;
      validForm = false;
    }

    if (validForm) {
      this.userAction.emit('SAVE');
      this.changeDetected = false;
    }
  }

  delete() {
    this.userAction.emit('DELETE');
  }

  addPendingPlaylist() {
    this.loadPendingPlaylist(true);
    this.playlistEditorService.deletePendingPlaylist({
      profileId: this.profileId!,
      playlistId: this.playlist?.id,
    });
    this.showAlert = false;
  }

  removePendingPlaylist() {
    this.playlistEditorService.deletePendingPlaylist({
      profileId: this.profileId!,
      playlistId: this.playlist?.id,
    });
    this.showAlert = false;
  }

  editPlaylistNameInput() {
    this.isEditingName = true;
    setTimeout(() => {
      this.playlistNameInput.nativeElement.select();
    });
  }

  revertToVersion(id: string) {
    const modal = this.modalService.open(PlaylistRevertVersionDialogComponent, {
      backdrop: 'static',
    });
    modal.result
      .then(() => {
        this.revertPlaylist(id);
      })
      .catch(() => {});
  }

  async revertPlaylist(id: string) {
    this.userAction.emit('START_REVERT');

    const {
      data: { playlist },
    } = await lastValueFrom(
      this.getPlaylistGQL.fetch({ id }, { fetchPolicy: 'network-only' }),
    );
    if (playlist) {
      // Retain assigned channels, locations, channel groups of the current playlist
      const currentChannelRegions = _.cloneDeep(
        this.playlist?.channelRegions || [],
      );
      this.playlist = cloneDeep(playlist) as Playlist;
      this.playlist.channelRegions = [...currentChannelRegions];
      this.setControlValues(this.playlist);

      // Add delay for the progress bar to display in cases where cached data is retrieved.
      setTimeout(() => {
        this.toasterService.success('Successfully reverted to version.');
        this.userAction.emit('END_REVERT');
        this.activeIdSettingsPanel.set(2);
      }, 500);
    } else {
      this.toasterService.error('Failed to revert to version');
      this.userAction.emit('END_REVERT');
    }
  }

  createCopy(id: string) {
    const modal = this.modalService.open(PlaylistCreateCopyDialogComponent, {
      backdrop: 'static',
    });
    modal.result
      .then(() => {
        this.duplicatePlaylist(id);
      })
      .catch(() => {});
  }

  duplicatePlaylist(id: string) {
    this.duplicatePlaylistsGQL.mutate({ ids: [id] }).subscribe(({ data }) => {
      if (
        data?.duplicatePlaylists.isSuccessful &&
        data.duplicatePlaylists.playlistIds?.length
      ) {
        const playlistId = this.encryptionService.encrypt(
          data.duplicatePlaylists.playlistIds[0] || '',
        );
        if (playlistId) {
          this.toasterService.success(
            'Successfully created a copy of the version.',
          );
          this.router.navigate(['/playlist/manage', playlistId]);
        }
      } else {
        this.toasterService.error('Failed to create a copy of the version.');
      }
    });
  }

  setCurrentTab(tab: PlaylistTabs) {
    this.currentTab = tab;
    this.playlistViewService.setPlaylistCurrentTab(this.currentTab);
  }
}
